@import "./variables.less";

.taro-button-core[type='primary'] {
  background-color: red;
  color: #fff;
}

.taro-model__confirm {
  color: #982e91 !important;
}

.taro-model__cancel {
  color: #3c3c3c !important;
}


.taro-model__btn .taro-model__confirm {
  color: red;
}

.taro__toast > :nth-child(2) {
  z-index: 99999 !important;
}

.weui-switch:after {
  height: 22px !important;
}

taro-switch-core {
  height: 24px !important;
}


.fx-page-render {
  background-color: white;
}

.fx-doc {
  position: relative;
}

.free-rect-stamp__toolbar {
  position: absolute;
  width: 160px;
  height: 30px;
  font-size: 14px;
  color: #262728;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  line-height: 34px;
  z-index: 9999;
}
.free-rect-stamp__toolbar::before {
  content: '';
  position: absolute;
  border-width: 8px; /* 调整三角形大小 */
  border-style: solid;
  z-index: 1; /* 确保三角形在 tooltip 上方 */
}

.free-rect-stamp__toolbar[data-position="top"] {
  //bottom: initial;
  //right: initial;
  //top: -48px;
  box-shadow: rgba(0,0,0, 0.24) 0px 3px 8px;
}
.free-rect-stamp__toolbar[data-position="top"]::before {
  left: 50%;
  transform: translateX(-50%);
  bottom: -13px; /* 调整三角形与 tooltip 的垂直位置 */
  border-color: #FFF transparent transparent transparent; /* 调整三角形颜色 */
}

.free-rect-stamp__toolbar[data-position="bottom"] {
  top: initial;
  right: initial;
  bottom: -48px;
  box-shadow: rgba(0, 0, 0, 0.24) 4px 0px 14px;
}
.free-rect-stamp__toolbar[data-position="bottom"]::before {
  left: 50%;
  transform: translateX(-50%);
  border-color: transparent  transparent #FFF transparent;
  top: -15px; /* 调整三角形与 tooltip 的垂直位置 */
}

.free-rect-stamp__toolbar[data-position="left"] {
  top: initial;
  bottom: initial;
  right: initial;
  left: -178px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.free-rect-stamp__toolbar[data-position="left"]::before {
  border-color: transparent  transparent transparent #FFF;
  right: -14px; /* 调整三角形与 tooltip 的垂直位置 */
}

.taro-textarea {
  height: 90%;
}
