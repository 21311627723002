* {
  margin: 0;
  padding: 0;

}

.container {
  /*border:1px solid gray;*/
  width: 60vw;
  padding: 0.2em 0.4em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  font-size: 0.4em;
  background-color: #fff;
  z-index: 99999;
  overflow: hidden;
  display: none;
}
.container .title {
  font-size: 0.5em;
}

#calendar {
  width: 100%;
  background-color: #fff;
  color: #3e3e3e;
  border-radius: 5px;
  margin: 0 auto;
}

#title {
  font-size: 1em;
  padding: 0.55em 0.55em;
}

#days th {
  font-weight: bold;
  text-align: center;
  padding: 0.55em 0.55em;
}

#calendar td {
  text-align: center;
  padding: 1.2em .8em;
}

.today {
  font-weight: bold;
  outline: 1px #982e91 solid;
  /*border: 1px #3e3e3e solid;*/
}

.active {
  font-weight: bold !important;
  color: #fff !important;
  background-color: #982e91 !important;
  outline: none !important;
}

.poin {
  cursor: pointer;
  cursor: hand;
}

.date-cell {
  cursor: pointer;
  cursor: hand;
}

.common-cell {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.toolBar {
  display: flex;
  justify-content: space-between;
  padding: .4em 1em 1em 1em;
}

#cleanBtn, #todayBtn {
  color: gray;
  cursor: pointer;
  cursor: hand;
  color: #3e3e3e;
}
