.date-input{
    position: relative;
}

.date-input::-webkit-calendar-picker-indicator{
     position: absolute;
     right: 0;
     height: 100%;
     padding-left: 100%;
     padding-right: 0px;
 }

.esign-disable {
  disable: true;
  pointer-events: none;
}

.outline-hidden {
  outline: none;
}


.sign-drapbox {
  left:2px;
  bottom:-215px;
  z-index:2;
  position: absolute;
  width: 240px;
  overflow-y: scroll;
  height: 217px;
  padding: 20px 15px 15px 20px;
  background: #FFFFFF;
  border: solid 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  .setting {
    font-weight: normal;
    color: #8C8C8C;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 5px;
    i {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      display: inline-block;
      background-image: url("../../assets/pure/setting.svg");
    }
    &:hover {
      color: #923084;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: calc(48%);
      display: flex;
      align-items: center;
      cursor: pointer;
      outline: 1px #eee solid;
      margin-bottom: 8px;
      padding: 5px;
      &.in {
        line-height: 56px;
      }
      &:hover,
      &:active {
        outline-color: rgba(210, 133, 229, 0.8);
      }
      .img {
        width: 100%;
        object-fit: contain;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }
  }


}




